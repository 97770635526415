<script setup lang="ts">
import placeholder from "~~/public/placeholder.webp";
import { generateUrl, type Options } from "@imgproxy/imgproxy-js-core";
import type { Schemas } from "#shopware";
import { getTranslatedProperty } from "@shopware/helpers";

const RETINA_SCALES = [2];

const props = withDefaults(
  defineProps<{
    media:
      | Pick<Schemas["Media"], "path" | "alt" | "updatedAt" | "translated">
      | undefined;
    resizingType?: Options["resizing_type"];
    extend?: boolean;
    width?: number;
    height?: number;
    alt?: string;
    dprScaling?: boolean;
  }>(),
  {
    resizingType: "fit",
    extend: false,
    width: undefined,
    height: undefined,
    alt: undefined,
    dprScaling: true,
  },
);
const {
  media,
  resizingType,
  extend,
  width: propWidth,
  height: propHeight,
  alt: propAlt,
  dprScaling,
} = toRefs(props);

const autoMode = computed(
  () => propWidth.value === undefined && propHeight.value === undefined,
);

const element = ref<HTMLImageElement>();
const { width: elemWith, height: elemHeight } = useElementSize(element);

const w = ref<number>(
  autoMode.value ? Math.round(elemWith.value) : propWidth.value || 0,
);
const h = ref<number>(
  autoMode.value ? Math.round(elemHeight.value) : propHeight.value || 0,
);

const cacheQuery = computed(() => {
  if (!media.value?.updatedAt) return "";
  return "?ts=" + new Date(media.value.updatedAt).getTime();
});

watch([propWidth, propHeight], ([n_w, n_h]) => {
  if (!!autoMode.value) return;

  w.value = n_w || 0;
  h.value = n_h || 0;
});

watch([elemWith, elemHeight], ([n_w, n_h]) => {
  if (!autoMode.value) return;

  const r_w = Math.round(n_w);
  const r_h = Math.round(n_h);

  if (h.value === r_h && r_w <= w.value) return;

  if (w.value && h.value && w.value / h.value === r_w / r_h && r_w <= w.value)
    return;

  w.value = r_w;
  h.value = r_h;
});

const src = ref(placeholder);
const srcsetData = ref<[string, string][]>();
const srcset = computed(() => {
  if (srcsetData.value === undefined) return undefined;
  return srcsetData.value.map((x) => x.join(" ")).join(", ");
});
const displayAlt = computed(
  () => propAlt.value || getTranslatedProperty(media.value, "alt"),
);

effect(() => {
  if (media?.value?.path === undefined) return;

  if (w.value === undefined || h.value === undefined) return;

  src.value = generateImageUrl(media.value.path, w.value, h.value);

  if (dprScaling.value) {
    srcsetData.value = [];
    for (const scale of RETINA_SCALES) {
      srcsetData.value.push([
        generateImageUrl(
          media.value.path,
          w.value * scale,
          h.value * scale,
          true,
        ),
        `${scale}x`,
      ]);
    }
  }
});

function generateImageUrl(
  path: string,
  width: number,
  height: number,
  enlarge: boolean = false,
) {
  return (
    "/image" +
    generateUrl(
      { value: path, type: "plain" },
      {
        resize: {
          width,
          height,
          resizing_type: resizingType.value,
          enlarge,
          extend: {
            extend: extend.value,
          },
        },
      },
    ).replace("/plain", "") +
    cacheQuery.value
  );
}
</script>
<template>
  <img
    ref="element"
    :src="src"
    :srcset="srcset"
    :alt="displayAlt"
    :width="!autoMode ? propWidth : undefined"
    :height="!autoMode ? propHeight : undefined"
  />
</template>
